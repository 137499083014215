import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/blogPost_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AudioPlayer = makeShortcode("AudioPlayer");
const Aligner = makeShortcode("Aligner");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Video = makeShortcode("Video");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "signature-stories-and-more-meaningful-economies",
      "style": {
        "position": "relative"
      }
    }}>{`Signature Stories and More Meaningful Economies`}<a parentName="h1" {...{
        "href": "#signature-stories-and-more-meaningful-economies",
        "aria-label": "signature stories and more meaningful economies permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/signature_space_launch.mp3" mdxType="AudioPlayer" />
    <p>{`You can listen to the social launch of the essay above, or go on and read about all the juicy technical details below.`}</p>
    <hr></hr>
    <p>{`We have created an interactive essay intended to expand our understanding of ownership so that it includes reciprocity and care, rather than just control and possession.`}</p>
    <Aligner center mdxType="Aligner">
      <Flex sx={{
        alignItems: 'center'
      }} mdxType="Flex">
  
  <Button to='https://sign.kernel.community' secondary inline mdxType="Button">
    Make your mark
  </Button>
      </Flex>
    </Aligner>
    <p>{`We wish to make new media which illustrate how to play with public tools for good thought in manifold ways. In particular, these ways ought to include the financial, but not be limited to it. We believe that the work of web3 is about programming in appropriate and contextual ways; not reducing everything to finance, nor using shared ledgers to sell hyped-up narratives about how we can solve all the problems of the world.`}</p>
    <p>{`In crafting one such public `}<a parentName="p" {...{
        "href": "/learn/module-3/remember/"
      }}>{`tool for thought`}</a>{`, we have - out of necessity - made a few technical discoveries and innovations. This is the story of our shared work.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/oBE8ToQS6CI" mdxType="Video" />
    <h1 {...{
      "id": "free-signs",
      "style": {
        "position": "relative"
      }
    }}>{`Free Signs`}<a parentName="h1" {...{
        "href": "#free-signs",
        "aria-label": "free signs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`First, we needed some non-financial means of interacting with text significantly. We initially began our work as a fork of the `}<a parentName="p" {...{
        "href": "https://www.interdependence.online/declaration"
      }}>{`Declaration of Interdependence`}</a>{`, and so had in mind from the start some notion of “signing” the text permanently and meaningfully.`}</p>
    <p>{`What this entails in practice is crafting an Ethereum Signed Message of the whole text and storing it somewhere permanent so that it may be referenced as an ongoing part of the document’s shared history. If you do not submit it to Ethereum itself, then this signed message is entirely free, and can be verified by anyone without any need to touch the chain itself.`}</p>
    <p>{`What does “signing” mean in this context, though? Do I have to agree to the whole text? Does it make me a co-creator or co-beneficiary? Or is it more like a clap, or a like? `}</p>
    <p>{`While it is certainly closer to endorsement than co-creation in our context, a signed message links permanently your public key to some data. We have always liked the analogy that your public key is like your home address (and can be shared), while your private key is like your bathroom door key (don’t share it ever). If we extend this analogy, then signed messages are like what you choose to plant in your front garden. As opposed to claps, which can only be seen on Medium, every time someone passes your home on the chain, they’ll be able to see what you’re growing there, irrespective of the media in which they encounter it.`}</p>
    <p>{`The declaration was initially designed to use social verification, which we decided not to implement. You ought to be able to interact meaningfully with just a keypair: you do not need to reveal anything more about yourself than this if you do not wish to. Moreover, we changed the way these signatures are stored in Arweave so that they can be `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/dapp/src/utils/arweave.js#L70L77"
      }}>{`more easily retrieved`}</a>{`.`}</p>
    <p>{`We also have our own infrastructure already running for `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/services"
      }}>{`Kernel services`}</a>{`, which allows us to integrate the upload to Arweave into our wider family of focused web apps. There is much more to come on this particular front.`}</p>
    <h1 {...{
      "id": "sealing-the-deal",
      "style": {
        "position": "relative"
      }
    }}>{`Sealing the Deal`}<a parentName="h1" {...{
        "href": "#sealing-the-deal",
        "aria-label": "sealing the deal permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The second manner in which you may interact with the text is explicitly financial. However, it is also the method which allows for the most choice of the three possible interactions, because the price is left open to readers to specify by using a slider. The price you choose to pay for one of these seals also determines the shape of the seal that gets created. The more you pay, the more edges the seal has; moving from square to hexagonal to circular.`}</p>
    <p>{`Critically, this explicitly financial interaction has only one effect: all funds are spent on free(ing) education. We feel this makes for a strangely recursive loop, the likes of which could help fund public goods sustainably across our ecosystems. Creating a seal is a public and verifiable commitment to a simple idea: free education matters, in the sense of both cost and autonomy - which is something the mechanism by which these seals are minted mirrors.`}</p>
    <p>{`We have a `}<a parentName="p" {...{
        "href": "/learn/module-1/playdough-protocols"
      }}>{`long-running interest in seals`}</a>{`. Not only have they been used to ensure data integrity for more than 3,000 years; they are also the means by which we can trace trade networks and the concomitant ways in which trust has spread across the globe (i.e. the fact Sumerian seals are found in Mali signifies that a network existed between these places); as well as being directly linked to the development of writing.`}</p>
    <p>{`This work intends to extend integrity and trust into the interplanetary systems we now have available to us. In particular, we were inspired by the `}<a parentName="p" {...{
        "href": "https://app.rewilder.xyz"
      }}>{`Rewilder`}</a>{` team. However, when we looked at their contracts, we noticed that `}<a parentName="p" {...{
        "href": "https://github.com/maraoz/rewilder/blob/dapp/contracts/RewilderNFT.sol#L29"
      }}>{`they use their own api`}</a>{` and became determined to do better.`}</p>
    <p>{`There are two ways immediately available for improving this. The first is to explore `}<a parentName="p" {...{
        "href": "https://docs.ipfs.io/concepts/ipns/"
      }}>{`IPNS`}</a>{` in order to host metadata for all the potential NFTs in a more dynamic way. However, we knew of a simpler and far more delightfully hacky solution.`}</p>
    <p>{`You can upload entire directories to Arweave and, if you structure that directory in a clever way, you can access all kinds of metadata in a ‘dynamic’ fashion from the same permaweb link. You get all the benefits of an api, with none of the costs or security/impermanence issues.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/tree/main/packages/hardhat/assets/arweave-seals"
      }}>{`Here is the directory`}</a>{` we uploaded to Arweave. `}<a parentName="p" {...{
        "href": "https://arweave.net/SuPXgNnLyr_X4FR-a9M3jTloVH9wZnN334g9ToxyNZU/"
      }}>{`Here it is`}</a>{` on Arweave. We simply use this link as the baseURI for the tokens, and then specify which subdirectory and JSON file to add to the original link for each specific token `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/hardhat/contracts/SignatureFund.sol#L101"
      }}>{`depending on the msg.value`}</a>{` of the call used to mint that token.`}</p>
    <h2 {...{
      "id": "recurrent-seasons",
      "style": {
        "position": "relative"
      }
    }}>{`Recurrent Seasons`}<a parentName="h2" {...{
        "href": "#recurrent-seasons",
        "aria-label": "recurrent seasons permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`There’s one more important thing to note in `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/hardhat/contracts/SignatureFund.sol#L32"
      }}>{`this contract`}</a>{`. We know we could have made it much more gas efficient by storing the URI in a tokenURI() function that overrides the ERC721 standard, which would be view only. However, we want to be able to update the seals over time, which means pointing the baseURI for metadata to a different Arweave URL. It is therefore critical that we store the full string in each tokenURI, so that seals which have already been minted remain fixed, while new seals can be generated with a different season of images and text.`}</p>
    <p>{`This allows us to explore ongoing, sustainable, seasonal interaction with the contract and with the wider web3 community. It also allows us to play with limits and constraints which are not contrived. That is: there is no limit to the number of seals which can be minted, because 10000 PFP campaigns cultivate the kind of consumerist culture we find to be boring and distasteful.`}</p>
    <p>{`However, everyone’s time is limited. It is not limited by some human contrivance in a structure we have designed and created: it is limited in an existential way. Therefore, placing a time limit on how long these particular seals will be created allows us to generate the kind of motivation to mint you might see in other projects, with less unnecessary anxiety and FOMO. We say this because seasonality implies not only change, by `}<strong parentName="p">{`cyclicality`}</strong>{`. It is entirely possible that this season of seals will one day become available again: it’s just not clear when and what will come between then and now.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When we cast these new networks as economies of sign rather than of ownership, then the game becomes one of actively finding more people able to respond, rather than extracting rent from my exclusive right to some artificially scarce series of bits.`}</p>
    </blockquote>
    <h1 {...{
      "id": "highlight-of-the-show",
      "style": {
        "position": "relative"
      }
    }}>{`Highlight of the Show`}<a parentName="h1" {...{
        "href": "#highlight-of-the-show",
        "aria-label": "highlight of the show permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Lastly, we needed some visually meaningful, implicitly financial means of interacting with the text which allows readers to make their own permanently significant mark. This is easy enough to write out in natural language, but opening up such a route within this new medium would prove surprisingly challenging.`}</p>
    <p>{`We were inspired by `}<a parentName="p" {...{
        "href": "https://www.productboard.com/blog/how-medium-highlights-feature-changed-publishing/"
      }}>{`Medium’s highlights`}</a>{` and the manner in which those have been used both to foster a greater community of readers and writers sharing what they think and giving each other valuable feedback across different media, all of which increases total time reading.`}</p>
    <p>{`We knew we wanted to achieve something similar, except with more visual impact and using NFTs. We also knew that using NFTs would imply a cost for readers which would be helpful in mitigating spam, and so we wrote the initial contracts with an entirely open mint function. Anybody could call the mint function, provide it with any string, and it would create a NFT with the tokenURI set to that string. `}</p>
    <p>{`We knew the web app could send strings which referred to images generated using the text, and that we could exclude all other NFTs not minted in this manner from the “`}<a parentName="p" {...{
        "href": "https://sign.kernel.community/signatures"
      }}>{`Soul Signs`}</a>{`” page. There is a difference between `}<em parentName="p">{`contractual reality`}</em>{` - which everyone has access to and can do with as they please; and `}<em parentName="p">{`social reality`}</em>{` - in which we can present curated data most relevant to our community and context through various interface choices.`}</p>
    <p>{`This, at least, was the argument we presented to the wonderful developer who audited the contracts. With deep experience in the world of NFTs, this developer made the point that having an open and permissionless mint function was all well and good until a troll comes along and uses it to mint horrific and illegal content, which then results in your collection getting banned from secondary markets. `}</p>
    <p>{`While such markets are not the major focus of our work, it would be a pity to have done all this only to get banned for someone else’s actions. In addition to this, we have implemented a `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/hardhat/contracts/base/ERC2981ContractWideRoyalties.sol"
      }}>{`10% contract-wide royalty`}</a>{` for secondary sales of Signature NFTs, so getting banned from marketplaces will likely lose us some potentially useful revenue in the long term.`}</p>
    <h2 {...{
      "id": "better-ways-of-being-open",
      "style": {
        "position": "relative"
      }
    }}>{`Better Ways of Being Open`}<a parentName="h2" {...{
        "href": "#better-ways-of-being-open",
        "aria-label": "better ways of being open permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Initially, we discussed the possibility of only allowing the creator of the contract to mint Signature NFTs. However, this defeats the whole point of permissionless systems, and introduces a potentially catastrophic security breach if the keys for that creator account are ever compromised. `}</p>
    <p>{`So, we decided to add a “signer” account to the contract, separate from the creator. Though anyone could call mint(), the signer account would have to sign any message sent to that function, which now included a data param for that signed message, and which would fail if it had not been signed correctly. Trying to have our cake and eat it too, we made sure that we could `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/cb38481ad4d5a21b32ff618abb3b92eede80e52a/packages/hardhat/contracts/SignatureNFT.sol#L86"
      }}>{`set the signer account to 0x0`}</a>{` if we wanted, in which case it would be equivalent to a purely public function. The thinking here was, “permissionless, but not unmoderated”.`}</p>
    <p>{`This seemed to be an OK solution: not wonderful, but workable given our time constraints.`}</p>
    <p>{`However, when we began working it into the web app, we saw how much unnecessary complexity this simple change at the contractual level added to the overall project. It required running a separate server somewhere which had the signer private key on it, then authenticating calls to that server, as well as the data in those calls, trying to obfuscate network requests made from the frontend (not really possible) and many other things which any “authentication” paradigm necessarily implies.`}</p>
    <p>{`It was a deep lesson in why passwords and privileged roles suck. `}<a parentName="p" {...{
        "href": "https://www.oreilly.com/openbook/freedom/ch07.html"
      }}>{`Richard Stallman`}</a>{` was heard laughing loudly in the background of many of those late nights.`}</p>
    <p>{`We returned to the drawing board. We needed some way of minting NFTs which was flexible enough to accommodate reader’s choices, but constrained enough that trolls would not use it to get our collection banned. We knew we could not use any kind of privileged account. The thought of trying to use the same technique as is outlined above for Sealed NFTs did cross our minds, but the number of different combinations of characters in a 2124 word essay is well over 2 million using the most optimistic of counts, and crafting all of that metadata was too much.`}</p>
    <p>{`Instead, we decided that all we needed to record when readers minted a Signature NFT was the start and end indices of the characters they had chosen. We could then use these start and end numbers to somehow generate the beautiful image you now see. `}</p>
    <p>{`However, we had already written the `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/dapp/src/components/HighlightModal/Canvas.js"
      }}>{`p5.js script`}</a>{` which generates those images and had grown quite attached to it. The problem is: you can’t run p5.js onchain. At best, you can write a `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x4325ac3371f5526fb4190e5b426355c141b85018#code"
      }}>{`room of infinite paintings`}</a>{` using all sorts of svg sleight of hand. That, too, was not something we felt capable of doing. However, onchain svgs do use a trick we could leverage: a `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/hardhat/contracts/SignatureNFT.sol#L114"
      }}>{`tokenURI() override function`}</a>{` that returns metadata directly from the contract using base64 encoded json. This resulted in a major`}</p>
    <Aligner center mdxType="Aligner">
      <Image src="/images/blog_headers/brainwave.jpg" mdxType="Image" />
    </Aligner>
    <p>{`We could take our p5.js script, turn it into a `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/tree/main/packages/metta-sign"
      }}>{`little single page react app`}</a>{` that accepts start and end points in the url, `}<a parentName="p" {...{
        "href": "https://opensea.mypinata.cloud/ipfs/QmYHGGTbqwFtfLFhhAKojVbmJwkVMNyqrniG9HLL1PYpyD/#0-462"
      }}>{`upload that app to IPFS`}</a>{`, reference it `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/signature-economies/blob/main/packages/hardhat/contracts/SignatureNFT.sol#L40"
      }}>{`in the contract`}</a>{` and `}<em parentName="p">{`voila`}</em>{`! All we need to do is return metadata from the tokenURI() function which references the IPFS hash together with the start and end of the reader’s chosen highlight, and this little SPA will generate the image on demand. Moreover, we can be sure it will always be findable by that hash and so we have finally achieved our aim.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`We have a permissionless, open mint function which can only be used to mint Signature NFTs from the essay, and will produce them as dynamic images which nevertheless have a permanent and verifiable root.`}</strong></p>
    </blockquote>
    <h1 {...{
      "id": "signing-off-with-gratitude",
      "style": {
        "position": "relative"
      }
    }}>{`Signing Off With Gratitude`}<a parentName="h1" {...{
        "href": "#signing-off-with-gratitude",
        "aria-label": "signing off with gratitude permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`One last consideration we spent endless hours on was, “which network do we deploy on?” This has become more and more of a challenge for many kinds of projects in the last few years, which we think is a wonderful thing. However, our own context made the choice quite simple. It was not about attention, liquidity, active users, or any other metric. It comes down to this incredibly personal and sentimental signature. Paris is fast approaching and the world will never be the same. We wanted this project to sign off the last seven, beautiful years on Eth1, and to serve as a statement of thanks and praise to all  who have participated.`}</p>
    <p>{`We love you.`}</p>
    <p>{`It has been a pleasure to work on this. The Signature Economies have cultivated within many of us a greater `}<a parentName="p" {...{
        "href": "https://twitter.com/i/spaces/1OyKADYgjOrxb"
      }}>{`sense of care`}</a>{` and patience, as well as an understanding of what it really means to `}<a parentName="p" {...{
        "href": "https://twitter.com/i/spaces/1OdKrBWkMlVKX"
      }}>{`do memorable work`}</a>{`. We find ourselves looking back over the commit trail and all the many months of work, and finally beginning to resonate with some of the deeper words in the text itself. In particular:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Seek to improve the people around you by humbling yourself and handing over power at every chance, without shirking responsibility. Delighted, you will gradually find yourself doing nothing, being happy.`}</p>
    </blockquote>
    <p>{`We invite you, one and all, to `}<a parentName="p" {...{
        "href": "https://sign.kernel.community"
      }}>{`come and make eternally beautiful marks with us`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      